import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';
import {
  getOrderById,
  getOrderSettings,
  getOrderUserProfile,
  OrderSettings,
  OrderSettingsSubscription,
  OrderType,
  OrderUserProfile,
} from '@/services/requests/orders';
import { OrderFormMode } from '@/enums/order-form-mode';

type OrderStep = 1 | 2 | 3;

type CommonState = {
  step: OrderStep;
  mode: OrderFormMode | null;
  initialDataLoaded: boolean;

  errorMessage: string | null;

  settingsLoaded: boolean;
  settings: OrderSettings | null;

  userProfileLoaded: boolean;
  userProfile: OrderUserProfile | null;

  orderId: number | null;
  orderEdit: OrderType | null;
  orderEditLoaded: boolean;
  orderEditError: string | null;

  usedBalance: number;
};

const initialState: CommonState = {
  step: 1,
  mode: null,
  initialDataLoaded: false,

  errorMessage: null,

  settingsLoaded: false,
  settings: null,

  userProfileLoaded: false,
  userProfile: null,

  orderId: null,
  orderEdit: null,
  orderEditLoaded: false,
  orderEditError: null,

  usedBalance: 0,
};

export const loadOrderUserProfile = createAsyncThunk<OrderUserProfile | null>(
  'common/loadOrderUserProfile',
  async () => {
    try {
      const response = await getOrderUserProfile();
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const loadOrderSettings = createAsyncThunk<OrderSettings | null>(
  'common/loadOrderSettings',
  async () => {
    try {
      const response = await getOrderSettings();
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const loadOrder = createAsyncThunk<OrderType | null, { id: number }>(
  'common/loadOrder',
  async (arg) => {
    try {
      const response = await getOrderById(arg.id);
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    reset: () => initialState,
    setStep(state, action: PayloadAction<OrderStep>) {
      state.step = action.payload;
    },
    setMode(state, action: PayloadAction<OrderFormMode>) {
      state.mode = action.payload;
    },
    setInitialDataAsLoaded(state) {
      state.initialDataLoaded = true;
    },
    setErrorMessage(state, action: PayloadAction<string | null>) {
      state.errorMessage = action.payload;
    },
    setOrderId(state, action: PayloadAction<number>) {
      state.orderId = action.payload;
    },
    setUsedBalance(state, action: PayloadAction<number>) {
      state.usedBalance = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadOrderUserProfile.pending, (state, action) => {
        state.userProfile = null;
        state.userProfileLoaded = false;
      })
      .addCase(loadOrderUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload;
        state.userProfileLoaded = true;
        state.usedBalance = action.payload?.balance || 0;
      });

    builder
      .addCase(loadOrderSettings.pending, (state, action) => {
        state.settings = null;
        state.settingsLoaded = false;
      })
      .addCase(loadOrderSettings.fulfilled, (state, action) => {
        state.settings = action.payload;
        state.settingsLoaded = true;
      });

    builder
      .addCase(loadOrder.pending, (state, action) => {
        state.orderEdit = null;
        state.orderEditLoaded = false;
        state.orderEditError = null;
      })
      .addCase(loadOrder.fulfilled, (state, action) => {
        state.orderEdit = action.payload;
        state.orderEditLoaded = true;
      })
      .addCase(loadOrder.rejected, (state, action) => {
        state.orderEdit = null;
        state.orderEditLoaded = true;
        state.orderEditError = 'Ошибка загрузки заказа';
      });
  },
});

export function selectStep(state: AppState): OrderStep {
  return state.orderForm.common.step;
}

export function selectOrderMode(state: AppState): OrderFormMode | null {
  return state.orderForm.common.mode;
}

export function selectInitialDataLoaded(state: AppState): boolean {
  return state.orderForm.common.initialDataLoaded;
}

export function selectErrorMessage(state: AppState): string | null {
  return state.orderForm.common.errorMessage;
}

export function selectOrderUserProfileLoaded(state: AppState): boolean {
  return state.orderForm.common.userProfileLoaded;
}

export function selectOrderSettingsMinTotal(
  state: AppState
): number | undefined {
  return state.orderForm.common.settings?.minTotal;
}

export function selectOrderSettingsSubscriptions(
  state: AppState
): Array<OrderSettingsSubscription> | [] {
  return state.orderForm.common.settings?.subscriptions || [];
}

export function selectOrderSettingsLoaded(state: AppState): boolean {
  return state.orderForm.common.settingsLoaded;
}

export function selectOrderUserProfile(
  state: AppState
): OrderUserProfile | null {
  return state.orderForm.common.userProfile;
}

export function selectOrderId(state: AppState): number | null {
  return state.orderForm.common.orderId;
}

export function selectOrderEditData(state: AppState): OrderType | null {
  return state.orderForm.common.orderEdit;
}

export function selectOrderEditError(state: AppState): string | null {
  return state.orderForm.common.orderEditError;
}

export function selectOrderEditLoaded(state: AppState): boolean {
  return state.orderForm.common.orderEditLoaded;
}

export function selectIsOrderEdit(state: AppState): boolean {
  return !!state.orderForm.common.orderEdit;
}

export function selectUsedBalance(state: AppState): number {
  return state.orderForm.common.usedBalance;
}

export function selectSubscriptionDiscount(state: AppState): number {
  return state.orderForm.common.orderEdit?.discount || 0;
}

const actions = commonSlice.actions;
export const resetOrderCommonState = actions.reset;
export const setStep = actions.setStep;
export const setMode = actions.setMode;
export const setInitialDataAsLoaded = actions.setInitialDataAsLoaded;
export const setErrorMessage = actions.setErrorMessage;
export const setOrderId = actions.setOrderId;
export const setUsedBalance = actions.setUsedBalance;

export default commonSlice.reducer;
