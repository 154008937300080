import React from 'react';
import styled, { css } from 'styled-components';

import { useTypedSelector } from '@/store/store';
import {
  selectFirstServiceValue,
  selectSecondServiceValue,
} from '@/modules/Order/store/services';
import {
  selectOrderMode,
  selectOrderSettingsMinTotal,
  selectSubscriptionDiscount,
  selectUsedBalance,
} from '@/modules/Order/store/common';
import { OrderFormMode } from '@/enums/order-form-mode';
import {
  selectSelectedDate,
  selectSelectedDateCoefficient,
  selectSelectedTime,
} from '@/modules/Order/store/datetime';
import {
  dateToHumanString,
  numberTo2,
  workTimeHumanString,
} from '@/utils/common';
import {
  selectBasePriceForDiscount,
  selectOrderLength,
  selectPriceWithoutDiscount,
  selectUserPrice,
} from '@/modules/Order/store';
import { selectPromoValue } from '@/modules/Order/store/promo';
import { PromoCodeType } from '@/enums/promo-code-type';
import { colors } from '@/constants/theme';
import { PromoCodeDiscountType } from '@/enums/promo-code-discount-type';
import {
  selectOrderSecondaryServices,
  selectOrderSecondaryServicesSelected,
} from '@/modules/Order/store/secondary-services';
import { media } from '@/utils/mixin';
import OrderFormPromoApply from '@/modules/Order/containers/OrderFormPromo/OrderFormPromoApply';
import { selectAddressType } from '@/modules/Order/store/address';
import { OrderHouseType } from '@/enums/order-house-type';

const getTitle = (
  mode: OrderFormMode,
  firstServiceQuantity: number,
  secondServiceQuantity: number | null
): string => {
  if (mode === OrderFormMode.Rooms) {
    return `Уборка квартиры с ${firstServiceQuantity} ${
      firstServiceQuantity === 1 ? 'жилой' : 'жилыми'
    } и ${secondServiceQuantity} ${
      secondServiceQuantity === 1 ? 'ванной' : 'ванными'
    } комнатой`;
  } else if (mode === OrderFormMode.Windows) {
    return `Мойка ${firstServiceQuantity} окон${
      secondServiceQuantity ? ' и ' + secondServiceQuantity + ' балконов' : ''
    }`;
  }
  return '';
};

const getDateTimeString = (date: Date | null, time: string | null): string => {
  const dateString = date ? dateToHumanString(date) : '';
  if (time && date) {
    return dateString + ' в ' + time;
  } else if (time) {
    return time;
  } else if (date) {
    return dateString;
  } else {
    return '';
  }
};

const getLengthString = (minutes: number): string => {
  if (minutes < 60) {
    return `${minutes} мин.`;
  }

  return ` ${Math.floor(minutes / 60)} ч.${
    minutes % 60 > 0 ? ' ' + (minutes % 60) + ' мин.' : ''
  }`;
};

type Props = {
  isMobile?: boolean;
};

const OrderFormDetailedSummary: React.FC<Props> = (props) => {
  const minTotal = useTypedSelector(selectOrderSettingsMinTotal);
  const mode = useTypedSelector(selectOrderMode);
  const firstPrimaryServiceValue = useTypedSelector(selectFirstServiceValue);
  const secondPrimaryServiceValue = useTypedSelector(selectSecondServiceValue);
  const selectedDate = useTypedSelector(selectSelectedDate);
  const selectedDateCoefficient = useTypedSelector(
    selectSelectedDateCoefficient
  );
  const selectedTime = useTypedSelector(selectSelectedTime);
  const minutes = useTypedSelector(selectOrderLength);
  const userPrice = useTypedSelector(selectUserPrice);
  const priceWithoutDiscount = useTypedSelector(selectPriceWithoutDiscount);
  const priceBaseForDiscounts = useTypedSelector(selectBasePriceForDiscount);
  const promoValue = useTypedSelector(selectPromoValue);
  const usedBalance = useTypedSelector(selectUsedBalance);
  const type = useTypedSelector(selectAddressType);

  const secondaryServicesSelected = useTypedSelector(
    selectOrderSecondaryServicesSelected
  );
  const secondaryServices = useTypedSelector(selectOrderSecondaryServices);

  const dateTimeValue = getDateTimeString(selectedDate, selectedTime);

  const discounts = [];

  if (usedBalance) {
    discounts.push({
      label: `Взято с баланса`,
      value: '-' + Math.min(priceWithoutDiscount, usedBalance) + ' BYN',
    });
  }

  if (promoValue && promoValue.type === PromoCodeType.Referral) {
    discounts.push({
      label: `Реферральный код - ${promoValue.code}`,
      value: '-' + promoValue.discount + ' BYN',
    });
  }

  if (type === OrderHouseType.House) {
    discounts.push({
      label: 'Частный дом',
      value: '+20%',
      isRed: true,
    });
  }

  const promoDiscount =
    promoValue && promoValue.type === PromoCodeType.Promo
      ? promoValue.discountType === PromoCodeDiscountType.Percent
        ? priceBaseForDiscounts * promoValue.discount * 0.01
        : promoValue.discount
      : 0;

  const dateDiscount =
    selectedDateCoefficient && selectedDateCoefficient < 1
      ? priceBaseForDiscounts * (1 - selectedDateCoefficient)
      : 0;

  const subscriptionDiscount = useTypedSelector(selectSubscriptionDiscount);

  if (selectedDate) {
    if (selectedDateCoefficient < 1) {
      const dayDiscount = Math.round((1 - selectedDateCoefficient) * 100);
      discounts.push({
        label: 'Скидка - ' + dateToHumanString(selectedDate),
        value: '-' + dayDiscount + '%',
        crossed:
          (promoDiscount && dateDiscount < promoDiscount) ||
          (subscriptionDiscount && dateDiscount < subscriptionDiscount),
      });
    } else if (selectedDateCoefficient > 1) {
      discounts.push({
        label: 'Коэффициент - ' + dateToHumanString(selectedDate),
        value: '+' + Math.round((selectedDateCoefficient - 1) * 100) + '%',
        isRed: true,
      });
    }
  }

  if (promoValue && promoValue.type === PromoCodeType.Promo) {
    discounts.push({
      label: `Промокод - ${promoValue.code}`,
      value:
        '-' +
        promoValue.discount +
        ' ' +
        (promoValue.discountType === PromoCodeDiscountType.Percent
          ? '%'
          : ' BYN'),
      crossed:
        (dateDiscount && promoDiscount < dateDiscount) ||
        (subscriptionDiscount && promoDiscount < subscriptionDiscount),
    });
  }

  if (subscriptionDiscount) {
    discounts.push({
      label: `Скидка по подписке`,
      value: `-${subscriptionDiscount}%`,
      crossed:
        subscriptionDiscount < promoDiscount ||
        subscriptionDiscount < dateDiscount,
    });
  }

  const params = [];
  if (dateTimeValue) {
    params.push({
      label: 'Дата и время уборки',
      value: getDateTimeString(selectedDate, selectedTime),
    });
  }

  params.push({
    label: 'Продолжительность уборки',
    value: getLengthString(minutes),
    bold: true,
  });

  const services: Array<{
    label: string;
    price: number;
  }> = [];
  secondaryServicesSelected.forEach((secondaryServiceValue) => {
    const service = secondaryServices.find(
      (item) => item.id === secondaryServiceValue.id
    );

    if (!service) return;

    let serviceCount = null;
    if (service.mode === 'QUANTITY') {
      serviceCount = `${secondaryServiceValue.value} шт.`;
    } else if (service.mode === 'TIME' && secondaryServiceValue.value) {
      serviceCount = `${workTimeHumanString(
        Number(secondaryServiceValue.value)
      )}`;
    }

    let price = service.price;
    if (service.mode === 'QUANTITY') {
      price = service.price * Number(secondaryServiceValue.value);
    } else if (service.mode === 'TIME') {
      price =
        service.price * (Number(secondaryServiceValue.value) / service.step);
    }

    services.push({
      label: service.name + (serviceCount ? ' (' + serviceCount + ')' : ''),
      price,
    });
  });

  return (
    <>
      {minTotal && priceWithoutDiscount < minTotal ? (
        <MinTotalLabel>
          Минимальная сумма заказа: <b>{minTotal?.toFixed() + ' руб.'}</b>
        </MinTotalLabel>
      ) : null}

      {mode !== null && firstPrimaryServiceValue ? (
        <Title>
          {getTitle(
            mode,
            firstPrimaryServiceValue.quantity,
            secondPrimaryServiceValue?.quantity || null
          )}
        </Title>
      ) : null}

      {params.map((param) => (
        <Param>
          <ParamName>{param.label}</ParamName>
          <ParamValue color={!!param.bold ? 'green' : undefined}>
            {param.value}
          </ParamValue>
        </Param>
      ))}

      {services.length ? (
        <>
          <ParamHr />
          <ParamTitle>Дополнительно</ParamTitle>
          {services.map((service) => (
            <Param withPlus={true}>
              <ParamName>{service.label}</ParamName>
              <ParamValue>{service.price} BYN</ParamValue>
            </Param>
          ))}
        </>
      ) : null}

      {discounts.length ? (
        <>
          <ParamHr />
          <Param>
            <ParamName>Сумма заказа</ParamName>
            <ParamValue>{priceWithoutDiscount.toFixed(2)} BYN</ParamValue>
          </Param>
          {discounts.map((item, ind) => (
            <Param key={ind}>
              <ParamName>{item.label}</ParamName>
              <ParamValue
                color={item.isRed ? 'red' : 'green'}
                crossed={!!item.crossed}
              >
                {item.value}
              </ParamValue>
            </Param>
          ))}
        </>
      ) : null}

      <ParamHr />

      <Price>
        <PriceLabel>К оплате (BYN)</PriceLabel>
        <PriceValue>
          {Math.floor(userPrice)}{' '}
          <sub>
            {numberTo2(Math.round((userPrice - Math.floor(userPrice)) * 100))}
          </sub>
        </PriceValue>
      </Price>

      <PromoApply isMobile={!!props.isMobile} />
    </>
  );
};

const PromoApply = styled(OrderFormPromoApply)`
  margin-top: 10px;
  border-top: 1px solid rgb(237, 237, 237);
  padding-top: 20px;
`;

const Title = styled.span`
  display: block;
  color: rgb(33, 33, 33);
  line-height: 24px;
  margin-bottom: 15px;
  font-weight: 700;
`;

const Param = styled.li<{ withPlus?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.withPlus
      ? css`
          position: relative;
          padding-left: 14px;

          &:before {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            top: 3px;
            left: 0px;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB4PSIxNCIgeT0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTQuNzUgNC43NUg4di0xLjVINC43NVYwaC0xLjV2My4yNUgwdjEuNWgzLjI1VjhoMS41VjQuNzV6IiBmaWxsPSIjMTJDRjdDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==');
          }
        `
      : null}
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

const MinTotalLabel = styled.span`
  display: block;
  background: ${colors.red};
  color: ${colors.white};
  text-align: center;
  padding: 10px 5px;
  margin: -25px -30px 20px -30px;
`;

const ParamHr = styled.hr`
  height: 1px;
  border: 0 none;
  width: 100%;
  display: block;
  margin: 15px 0;
  background: rgb(237, 237, 237);
`;

const ParamName = styled.span`
  display: block;
  font-size: 14px;
  color: rgb(134, 143, 155);
  font-weight: 700;
  white-space: nowrap;

  ${media.tabletSmall(css`
    font-size: 13px;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
  `)}
`;

const ParamValue = styled.span<{ color?: 'red' | 'green'; crossed?: boolean }>`
  color: ${(props) =>
    props.color === 'green'
      ? 'rgb(18, 207, 124)'
      : props.color === 'red'
      ? colors.red
      : 'rgb(61, 61, 61)'};
  font-weight: ${(props) => (props.color ? 'bold' : '400')};
  white-space: nowrap;
  display: block;
  margin-left: 10px;

  ${media.tabletSmall(css`
    font-size: 13px;
  `)}

  ${(props) =>
    props.crossed &&
    css`
      text-decoration: line-through;
      opacity: 0.5;
    `}

  ${media.tabletSmall(css`
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  `)}
`;

const ParamTitle = styled.span`
  margin-bottom: 20px;
  font-weight: 600;
  color: rgb(134, 143, 155);
  font-size: 16px;
  display: block;
`;

const Price = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
`;

const PriceLabel = styled.span`
  font-size: 15px;
  color: rgb(61, 61, 61);
  font-weight: 600;
`;

const PriceValue = styled.span`
  font-size: 30px;
  font-weight: 900;
  color: rgb(18, 207, 124);
  margin-right: -6px;

  sub {
    top: -0.5em;
    left: -5px;
    font-weight: 700;
  }
`;

export default OrderFormDetailedSummary;
